@media (max-width: 770px) {
  .deposit-wrapper .col5 {
    width: 100px !important;
  }

  .deposit-dialog button,
  .withdrawal-dialog button {
    line-height: 50px !important;
  }
  .deposit-wrapper .col1 {
    width: 115px !important;
  }
}

@media (max-width: 400px) {
  .logok {
    height: 30px;
  }
  .deposit-wrapper .col1 {
    width: 80px !important;
  }
}
/* .linked:hover {
  color: #0000ff !important;
} */

/* #highlight-link:hover {
  color: #59f470 !important;
} */

#currency {
  cursor: pointer;
}
.cursor {
  position: relative;
}
.cursor i {
  position: absolute;
  width: 5px;
  height: 100%;
  background-color: #4f75ff;
  right: -10px;
  top: -3%;
  animation-name: blink;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  opacity: 1;
  transform: skew(-10deg);
}

.cursor span:focus + i {
  display: none;
}
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#or {
  font-weight: 700;
  font-size: 16px;
  color: white;
  padding: 0 10px 0 10px;
}

/* #depositsvg {
  width: 310px;
  padding-left: 50px;
  padding-top: 5px;
} */
/* #continuesvg {
  width: 220px;
  padding: 30px 0 0 15px;
  vertical-align: inherit;
} */
#currency {
  width: 70px;
  padding-right: 10px;
  line-height: initial;
}
#amount {
  width: 130px;
  line-height: initial;
}

@media (max-width: 1000px) {
  #currency,
  #amount {
    font-size: 50px;
  }
}

@media (max-width: 700px) {
  #depositsvg {
    width: 194px;
    padding-left: 20px;
    padding-right: 34px;
    padding-top: 0px;
    transform: translateY(-5px);
  }

  #currency,
  #amount {
    font-size: 30px;
  }

  .cursor i {
    width: 2px;
    right: -6px;
  }
}

.bg {
  background-color: var(--headerbg);
}

.bgmask {
  -webkit-mask-image: linear-gradient(
    var(--maskstart),
    var(--masktop),
    var(--maskmid),
    var(--maskbot),
    var(--maskbot)
  );
  mask-image: linear-gradient(
    var(--maskstart),
    var(--masktop),
    var(--maskmid),
    var(--maskbot),
    var(--maskbot)
  );
}
