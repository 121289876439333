// ---------------OVO OSTAJE!!!! ----------------
.flex-table {
  display: flex;
  flex-wrap: wrap;
  color: var(--tabletext);
  &-row {
    width: 100%;
    display: flex;
    align-items: center;
    //border-bottom: 1px solid var(--bordercolor);

    &:last-child {
      border: none;
    }
    .flex-table-cell-content {
      text-align: center;
    }
    .flex-table-cell {
      text-align: center;
      &:first-child {
        text-align: left;
        .flex-table-cell-content {
          text-align: left;
        }
      }
      &:last-child {
        text-align: right;
        .flex-table-cell-content {
          text-align: right;
        }
      }
    }
    &-header {
      padding: 20px 0;
      .title-content {
        color: var(--tabletext);
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
  &-cell {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: hidden;
    list-style: none;
    &-heading {
      display: none;
    }
    &-content {
      padding: 26px 0;
    }
    &.date-cell,
    &.type-cell {
      color: var(--tabletext);
    }
    &.amount-cell {
      color: var(--tabletext);
    }
    &.status-cell {
      color: var(--tabletext);
    }
    &.status {
      text-align: center;
    }
  }
}

.gdpr-step {
  .flex-table-cell {
    padding: 0px;
    .flex-table-cell-content {
      padding: 8px 0;
    }
  }
}

@media (max-width: 767px) {
  .flex-table {
    &-row {
      padding-top: 20px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      //margin-bottom: 2em;
      &-header {
        display: none;
      }
    }
    &-cell {
      width: 100%;
      display: flex;
      align-items: center;
      .topic-cell {
        order: -1;
      }
      &-heading {
        display: inline-block;
        white-space: nowrap;
        flex: 1;
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
      }
      &-content {
        flex: 2;
        padding: 17px 0;
      }
      &.date-cell,
      &.amount-cell,
      &.status-cell,
      &.type-cell,
      &.total-cell {
        .flex-table-cell-content {
          text-align: right;
        }
      }
      &.topic-cell {
        font-size: 1.3rem;
      }
    }
  }
}
