.first {
  grid-area: first;
}

.second {
  grid-area: second;
}

.third {
  grid-area: third;
}

.fourth {
  grid-area: fourth;
}

.fifth {
  grid-area: fifth;
}

.sixth {
  grid-area: sixth;
}

.seventh {
  grid-area: seventh;
}

.eight {
  grid-area: eight;
}

.ninth {
  grid-area: ninth;
}
@media (max-width: 770px) {
  /* .carouselcta {
    height: 30px;
  } */
  .category-title {
    font-size: 20px;
  }
  .showall {
    font-size: 12px;
  }
  .lobby-wraper {
    .game-category {
      margin-top: 0px;
    }
    .game-category-header {
      height: 50px;
    }
  }
}

.grid-container-opened {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  //grid-template-rows: 50% 50%;
  grid-template-areas: "first first second third fourth" "first first fifth sixth seventh";
}

@media (max-width: 1250px) {
  .grid-container {
    grid-template-columns: 25% 25% 25% 25%;
    //grid-template-rows: 33.3% 33.3% 33.3%;
    grid-template-areas: "first first second third" "first first fourth fifth" "sixth seventh eight ninth";
  }
  .grid-container-opened {
    grid-template-columns: 25% 25% 25% 25%;
  }
}

@media (max-width: 950px) {
  .grid-container {
    grid-template-columns: 33.3% 33.3% 33.3%;
    //grid-template-rows: 33.3% 33.3% 33.3%;
    grid-template-areas: "first first second" "first first third" "fourth fifth sixth";
  }
  .grid-container-opened {
    grid-template-columns: 33.3% 33.3% 33.3%;
  }
}

@media (max-width: 770px) {
  .grid-container {
    grid-template-columns: 50% 50%;
    //grid-template-rows: 20% 20% 20% 20% 20%;
    grid-template-areas: "first first" "first first" "second third" "fourth fifth" "sixth seventh";
  }
  .grid-container-opened {
    grid-template-columns: 50% 50%;
  }
}

.grid-container-reverse {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  //grid-template-rows: 50% 50%;
  grid-template-areas: "fourth third second first first" "seventh sixth fifth first first";
}

.grid-container-reverse {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  //grid-template-rows: 50% 50%;
  grid-template-areas: "fourth third second first first" "seventh sixth fifth first first";
}

@media (max-width: 1250px) {
  .grid-container-reverse {
    grid-template-columns: 25% 25% 25% 25%;
    //grid-template-rows: 33.3% 33.3% 33.3%;
    grid-template-areas: "third second first first" "fifth fourth first first" " ninth eight seventh sixth";
  }
}

@media (max-width: 950px) {
  .grid-container-reverse {
    grid-template-columns: 33.3% 33.3% 33.3%;
    //grid-template-rows: 33.3% 33.3% 33.3%;
    grid-template-areas: "second first first" "third first first" "sixth fifth fourth";
  }
}

@media (max-width: 770px) {
  .grid-container-reverse {
    grid-template-columns: 50% 50%;
    //grid-template-rows: 20% 20% 20% 20% 20%;
    grid-template-areas: "first first" "first first" "third second" "fifth fourth" "seventh sixth";
  }
}
