$font-family-sans-serif: "Catamaran", Fallback, sans-serif;
$body-bg: var(--bgColorABC);
$link-color: var(--acolor);
$link-hover-color: #fff;
/* $link-hover-color: var(--fontHoverColorABC); */
$input-btn-padding-y: 0.75rem;
//$input-btn-padding-x: 1.5rem;
$pagination-custom-color: var(--paginationHoverColor);
$input-bg: var(--inputbgABC);
$input-placeholder-color: var(--inputphcolor);
$custom-select-border-width: 0;
$input-border-color: var(--inputbordercolorABC);
$input-color: var(--tabletext);
$input-border-radius: 0.5rem;
$link-hover-decoration: none;
$btn-border-width: 0;
$input-btn-focus-width: 0;
$input-focus-border-color: var(--brandcolor);
$custom-control-indicator-border-color: var(--inputbordercolorABC);
$custom-control-indicator-size: 1.5rem;
$progress-bar-bg: var(--brandcolor);
$modal-backdrop-bg: rgba(black, 0.5);
$input-disabled-bg: transparent;
$border-color: #0e214b;
$modal-content-border-radius: 0.5rem;
$pagination-bg: transparent;
$pagination-border-width: 1px;
$pagination-active-bg: var(--brandcolor);
$pagination-active-color: var(--paginationColor);
$list-group-color: #0554b3;
$list-group-bg: var(--bgColorABC);
$input-border-width: 0;
//$btn-padding-y: 2.5rem;
$custom-control-indicator-bg: transparent;
$custom-control-indicator-checked-color: #c30004;
$font-size-base: 0.875rem;
$progress-bg: var(--progressBar);
$progress-bar-bg: var(--welcomBonusFont);
$progress-border-radius: 18px;
$input-group-addon-color: var(--buttonFour);
//$modal-inner-padding: calc(2vw + 15px);
//$modal-content-bg: linear-gradient(top, #fefb00, #e7b000);
//$input-focus-color?
